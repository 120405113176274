import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { map, tap } from 'rxjs/operators';
import { SitesService } from '../../services/sites/sites.service';
import { MembershipStatusDto } from 'src/app/api/models';

const SUBSCRIPTION_EXP_SAFETY_MARGIN_HOURS = 36;

@Injectable({
  providedIn: 'root'
})
export class UserResolver  {
  membershipStatus = MembershipStatusDto;
  constructor(
    private userService: UserService,
    private router: Router,
    private sitesService: SitesService,
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.userService.fetchUser().pipe(
      tap((user) => {
        const expDateWithMargin = new Date(user.subscription?.expireAt)
          .setHours(new Date(user.subscription?.expireAt).getHours() + SUBSCRIPTION_EXP_SAFETY_MARGIN_HOURS)
        const isSubscriptionValid = expDateWithMargin > Date.now();
        if (!user.subscription && user.role === 'GUEST') {
          return;
        } else if ((!user.subscription || !isSubscriptionValid) && ((user.subscription?.status !== this.membershipStatus.PastDue))) {
          if (route.routeConfig.path !== 'plans' && route.routeConfig.path !== 'thank-you') {
            return this.router.navigate(['/plans'])
          }
        } else if (user.subscription && (user.subscription?.status === this.membershipStatus.Active || (user.subscription?.status === this.membershipStatus.PastDue) || (user.subscription?.isTrialing && isSubscriptionValid))) {
          if (route.routeConfig.path === 'plans') {
            return this.router.navigate(['/'])
          }
        }
      }),
      map((user) => user)
    );
  }
}